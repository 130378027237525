import { baseCommonStore } from '@/store/common'
import { recreationCoCosAES } from '@/helper/ASE_RSA'

const captchaId = 'e9ffb0660aa29b9a06fd0525a3992364'
const currentLocale = (locale: string) => {
  return {
    'zh-CN': 'zho',
    'zh-HK': 'zho-hk',
    'en-US': 'eng',
    'ta-IN': 'eng',
    'hi-IN': 'eng',
    'pt-BR': 'pon',
    'vi-VN': 'eng',
    'ko-KR': 'kor',
    'id-ID': 'ind',
    'th-TH': 'eng',
    'ja-JP': 'jpn',
    'ms-MY': 'eng',
  }[locale]
}
const geeTestBind = (onSuccess, onError) => {
  const { locale, setBotionData } = baseCommonStore.getState()
  window.initBotion(
    {
      captchaId,
      product: 'bind',
      language: currentLocale(locale), // 多语言
    },
    captcha => {
      captcha?.appendTo('#page-view') // 调⽤ appendTo 将验证码插⼊到⻚⾯中
      captcha.onReady(() => captcha.showCaptcha())
      captcha?.onSuccess(() => {
        const data = captcha?.getValidate() // 极验返回的相关验证数据
        const captchaKey = 'jbb9mn1vbhq1jizx' // 加密盐
        const encData = recreationCoCosAES(data, captchaKey) // 对返回的极验数据进行加密
        setBotionData(encData) // 存到 store
        onSuccess?.()
        captcha.destroy()
      })
      captcha.onError(() => {
        onError?.()
        captcha.destroy()
      })
      captcha.onClose(() => {
        onError?.()
        captcha.destroy()
      })
    }
  )
}

function useGeeTestBind() {
  return (onSuccess, onError) => geeTestBind(onSuccess, onError)
}

export { useGeeTestBind }
